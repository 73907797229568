@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

:root {
  --navy: #14213d;
  --orange: #fca311;
  --light-grey: #e5e5e5;
  --dark-green: #3a5a40;
  --slate: #748cab;
  --slate-transparent: #bac9dd3d;
}

* {
  margin: 0;
  pad: 0;
  box-sizing: border-box;
}
body {
 font-family: 'Nunito'; 
   min-width: 360px;
   overflow: auto;
}
html {
  scroll-behavior: smooth;
}
section {
  height: 100vh;
}
ul {
  list-style: none;
  display: flex;
  column-gap: 1.5rem;
}
a {
  text-decoration: none;
  color:  inherit;
}
header {
  position: sticky;
  width: 100%;
}
.header-content {
  background-color: #fff;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  margin: 0 auto;
}
.banner {
  display: flex;
  justify-content:space-between;
  padding: 0.25rem 1rem;
  background-color: var(--orange);
  font-weight: 800;
  overflow: auto;
  margin: 0 auto;
  align-items: center;
}
.banner button {
  border: none;
  transition: 0.2s all ease-in;
  background: none;
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.25rem;
  color: black;
}
#home {
  height: calc(100vh - 60px);
}
#home
.right-col {
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}
nav {
  align-items: center;
}
.follow {
  font-weight: 600;
  padding: 2%;
  border-radius: 5px;
}
.nav-link {
  color: black;
  font-size: 1.1rem;
  transition: 0.2s all ease-in-out;
  padding: 0.25rem;
  font-weight: 500;
  
}
.nav-link:hover {
  color: var(--orange);
  -webkit-box-shadow: 0px 3.5px 0px -1px rgba(252,163,17,1);
-moz-box-shadow: 0px 3.5px 0px -1px rgba(252,163,17,1);
box-shadow: 0px 3.5px 0px -1px rgba(252,163,17,1);
}
.logo {
  font-size: 1.1rem;
}
.logo:hover {
  color: var(--navy);
}
.container {
  width: 100%;
  display: flex;
  height: 100%;
}
.left-col {
  width: 50%; 
  padding: 1rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.container .left-col {
  justify-content: center;
}
.right-col {
  padding: 1rem;
  width: 50%;
  
}
.container .right-col {
  align-items: center;
  display: flex;
}
.right-col img {
  height: 50%;
}
.left-col h1 {
  font-size: 2rem;
  padding: 1rem 0;
}
.left-col p {
  padding-bottom: 0.75rem;
}
.left-col button {
  padding: 1rem;
  background-color: var(--dark-green);
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.2s all;
}
.left-col button:hover {
  border: 5px solid var(--dark-green);
  background: none;
  color: black;
}
.cc-container {
  display: flex;
  padding: 2rem;
  height: 100%;
}
.cc-container .right-col h1 {
  font-size: 2rem;
  padding: 1rem 0;
  text-align: center;
}
.cc-container .right-col p {
  text-align:right;
}
.left-col div ul {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.step {
  border: 1px transparent;
  padding: 1.5rem;
  border-radius: 10px;
  text-align: left;
  font-size: 1.25rem;
  background-color: var(--slate-transparent);
  transition: 0.2s all;
  overflow: hidden;
}
.step:hover {
  border: 1px solid black;
}
.step span {
  font-weight: 800;
  font-size: 1.5rem;
}
.right-col span {
  font-weight: 800;
  font-size: 1.01rem;
}
.cc-container .left-col {
  justify-content: center;
}
.cc-container .right-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.borg {
  justify-content:right;
}
.b-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 4rem;
  width: 100%;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
  row-gap: 0.5rem;
}
.b-container p {
  padding: 1rem;
}
.c-container button {
  padding: 1rem;
  font-weight: 700;
  border-radius: 25px;
  background-color: inherit;
  color: inherit;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
  width: auto;
  transition: 0.2s all;
}
#borg-button {
  color: black;
  background-color: var(--orange);
}
#borg-button:hover {
  background-color: #fff;
  border: 5px solid var(--orange);
}
.borg-box {
  width: auto;
  font-size: 2rem;
  background-color: var(--slate-transparent);
  border-radius: 15px;
  font-weight: 700;
  text-wrap:wrap;
}
.mio-button {
  background-color: var(--navy) !important;
  color: #fff !important;
}
.mio-button:hover {
  background-color: #fff !important;
  color: black !important;
  border: 5px solid var(--navy);
}
.c-container {
  width: 100%;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
  gap: 15px;
  margin: 5px 0;
}

.contact-container {
  display: flex;
  padding: 5rem;
  height: 100%;
  justify-content: space-between;
}
.contact-left {
  width: 50%;
  display: grid;
}
.icon-links div{
  font-size: 1.25rem;
  
  width: 70%;
}
.contact-left h3{
  font-size: 1.1rem;
  font-weight: 400;
  padding: 1.5rem 0.2rem;
  color: #565155;
}
.contact-left h1 {
  font-size: 3rem;
}
.contact-left p {
  padding: 1rem 0;
  color: #565155;
  width: 70%;
}

.contact-right {
  width: 50%;
  display: block;
  background-color: #1c1a1f;
  border-radius: 10px;
  height: 100%;
}
.contact-right h2 {
  color: #fff;
  padding: 3rem 2rem 0 2rem;
  font-size: 2rem;
}
.input-wrap.w-100 {
  grid-column: span 2;
}
.input-wrap {
  display: grid;
  row-gap: 0.5rem;
}
form {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 1rem;
}
.input-wrap label {
  color: #bfbfbf;
  row-gap: 50px;
}
.contact-input {
  color: var(--light-grey);
  background-color: #2c282e;
  border: none;
  width: 100%;
  border-radius: 5px;
  padding: 0.5rem;
}
form div textarea {
  background-color: #2c282e;
  width: 100%;
  padding: 0.5rem;
  color: black;
  resize: none;
  
}

.input-wrap button {
  padding: 1rem;
  border: none;
  background-color: #fff75c;
  color: #1c1a1f;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
}
#myBtn {
  opacity: 0;
  position: fixed; 
  bottom: 20px; 
  right: 30px; 
  z-index: 99; 
  border: 2px solid rgba(0, 0, 0, 0.3); 
  outline: none;
  background-color: #fff; 
  color: white; 
  cursor: pointer; 
  padding: 15px;
  border-radius: 10px; 
  font-size: 18px; 
  transition: opacity 0.3s ease-out;
}
#myBtn.show {
  opacity: 1; 
}
.follow a {
  color: blue;

}
#myBtn:hover {
  background-color: var(--slate-transparent); 
}
#contact-message {
  color: white;
}

@media all and (max-width: 759px) {
  .container {
    flex-direction: column;
  }
  .cc-container .right-col p {
    text-align: center;
  }
  .left-col, .right-col {
    width: 100%;
    height: 50%;
  }
  .container .right-col {
    justify-content: center;
    align-items: center;
  }
  .cc-container {
    height: auto;
    flex-direction: column;
    row-gap: 1rem;
  }
  .cc-container .right-col {
    justify-content: flex-start;
  }
  .right-col img{
    height: 100%;
  }
  .cc-container .left-col ul{
    padding: 0;
  }
  .cc-container .right-col h1 {
    
  }
  .contact-container {
    flex-direction: column;
    row-gap: 1rem;
  }
  .contact-container .contact-left,  .contact-container .contact-right{
    text-align: center;
    width: 100%;
  }
  .contact-left p {
    width: 100%;
  }
  .head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
@media all and (max-width: 590px) {
  body {
    max-width: 100vw;
    margin: auto;
    padding: auto;
  }
  .cc-container .right-col{
    height: 50%;
  }
  #about {
    height: auto;
    overflow: hidden;
  }
  .cc-container {
    flex:1;
    flex-direction: column;
  }
  .cc-container .right-col p {
    text-align: center;
  }
  .borg-button {
    font-size: medium;
    transition: 0.2s all;
    height: 100%;
  }
  .borg {
    height: auto;
    z-index: 1;
  }
  form {
    padding: 1rem;
  }
  .borg-box p {
    overflow-wrap:normal;
  }
  .borg-box {
    font-size: 1.5rem;
  }
} 
@media all and (max-width: 501px) {
  body {
    font-size: 1.25rem;
  }
  .borg-button {
    padding: 1rem 0.5rem;
  }
  .mio-button {
    padding: 1rem 0.5rem;
  }
  section {
    height: auto;
  }
  .contact-container {
    padding: 5rem 2rem;
  }
  .contact-left p {
    width: auto;
  }
}